<template>
  <div class="container">map组件</div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
export default defineComponent({
  name: "Map",
  props: ["data"],
  setup() {
    onMounted(() => {
      console.log("子组件加载了");
    });
    console.log("子组件加载了");
    return {};
  },
});
</script>

<style lang="scss" scoped>
</style>