
import { defineComponent, onMounted, reactive, toRefs, computed} from "vue";
import { useStore } from 'vuex'
import Map from "../../components/map/map.vue";
export default defineComponent({
  name: "My",
  components: {
    Map,
  },
  setup() {
    const data = reactive<any>({
      userInfoOptions: [
        {
          id: 0,
          num: 0.0,
          title: "余额",
        },
        {
          id: 1,
          num: 3816,
          title: "积分",
        },
        {
          id: 2,
          num: 0,
          title: "卡",
        },
        {
          id: 3,
          num: 0,
          title: "优惠券",
        },
        {
          id: 4,
          num: 0,
          title: "零钱",
        },
      ],
      orderOptions: [
        {
          id: 0,
          title: "待付款",
        },
        {
          id: 1,
          title: "待发货",
        },
        {
          id: 2,
          title: "待收货",
        },
        {
          id: 3,
          title: "待评价",
        },
        {
          id: 4,
          title: "退款/售后",
        },
         {
          id: 5,
          title: "评价",
        },
         {
          id: 6,
          title: "已完成",
        },
      ],
      num: 0,
      num1: 2
    });
    const store = useStore()
    const contNum = computed(() => {
      return data.num + data.num1
    })
    onMounted(() => {
      const nowTime: String = "9月27日";
      const tomorrowTime: any = "2022-09-28";
      const list: Array<any> = ["2022-09-27", "2022-09-29"];

      const startTime: any = "2022-09-27";
      const endTime: any = "2022-09-30";

      console.log("今天需要显示-", list.includes(nowTime));
      console.log("2022-09-27" < "2022-09-29");
      console.log("明天需要显示-", list.includes(tomorrowTime));



      /* -------------------------------------------handle-------------------------------------- */
      handleShowTime(nowTime, startTime, endTime);
      const lists: Array<any> = [
        {
          id: 0,
          name: "123",
        },
        {
          id: 0,
          name: "456",
        },
        {
          id: 2,
          name: "123",
        },
        {
          id: 0,
          name: "789",
        },
      ];
      let a: Array<any> = lists.filter((i: any) => {
        return i.id === 0;
      });
      console.log("a", a);
    });




    const handleShowTime = (
      nowTime: any,
      startTime: any,
      endTime: any
    ): any => {
      // let timeString:any = nowTime.split('')
      console.log(nowTime);
      let timeString: any = nowTime.replace(/[^\d]/g, "");
      console.log(timeString);
      console.log(startTime);
      console.log(endTime);
    };




    const handleAddCount = (): any => {
      // store.commit('handleAddCount')
      store.dispatch('asyncHandleSet', 2)
    }

    const handleSubCount = (): any => { 
      store.commit('handleSubCount', 2)
    }

    const handleGetItem = ():any => {
      store.commit('handleGet', 'count')
    }



    const getMinNum = (num1: any, num2: any) => {
      console.log(num1,num2)
      for(var i = 1; i < num2; i ++ ) {
        for(var j = 1; j< num2; j++) {
            if((num1 * i) === (num2 * j)) {
              console.log('最小公倍数', num1 * i)
              return
            }
        }
      }
     }
    getMinNum(4,6)


    /* 
    

    */

    const getMaxNum = (num1: any, num2: any) => {
      for(var i = 1; i < num1; i ++ ) {
        for(var j = 1; j< num1; j++) {
            if((num1 / i) === (num2 / j)) {
              console.log('最大公约数', num1 / i)
              return
            }
        }
      }
     }
    getMaxNum(12,16)

    return {
      store,
      ...toRefs(data),
      handleAddCount,
      handleSubCount,
      handleGetItem,
      contNum
    };
  },
});
